import api from '@/api/index'
// axios
import request from '@/utils/request'

// 生成普通版信用报告
export function creditBasicsReport (params) {
  return request({
    url: api.CreditBasicsReport,
    method: 'get',
    params,
    hideloading: true
  })
}

// 生成升级版信用报告
export function creditUpgradeReport (params) {
  return request({
    url: api.CreditUpgradeReport,
    method: 'get',
    params,
    hideloading: true
  })
}

// 生成金融版信用报告
export function creditUpgradeReportFinance (params) {
  return request({
    url: api.CreditUpgradeReportFinance,
    method: 'get',
    params,
    hideloading: true
  })
}

// 生成升级版信用报告下载地址
export function getDownLoadUrlByReportIdPlus (params) {
  return request({
    url: api.GetDownLoadUrlByReportIdPlus,
    method: 'get',
    params,
    hideloading: true
  })
}
// 生成金融版信用报告
export function getDownLoadUrlByReportIdPlusFinance (params) {
  return request({
    url: api.GetDownLoadUrlByReportIdPlusFinance,
    method: 'get',
    params,
    hideloading: true
  })
}

// 报告统一下单
export function createWebReportOrder (data) {
  return request({
    url: api.CreateWebReportOrder,
    method: 'post',
    data,
    hideloading: true
  })
}

// 查询报告订单在支付状态
export function getReportPayState (params) {
  return request({
    url: api.GetReportPayState,
    method: 'get',
    params,
    hideloading: false
  })
}

// 发送邮件
export function offline (data) {
  return request({
    url: api.Offline,
    method: 'post',
    data,
    hideloading: true
  })
}

// 查询报告订单在支付状态
export function getPayCount (params) {
  return request({
    url: api.GetPayCount,
    method: 'get',
    params,
    hideloading: false
  })
}
