<template>
  <div style="margin-top: 80px;min-height: 500px;">
    <el-row>
      <div class="container">
        <!--输入框-->
        <el-row style="margin-bottom: 30px">
          <el-col :span="13">
            <div>
              <el-autocomplete
                style="width: 100%"
                clearable
                class="inline-input"
                v-model="conditions.keyword"
                :fetch-suggestions="querySearch"
                placeholder="请输入企业名称关键词"
                :trigger-on-focus="false"
                @select="handleSelect"
                size="medium"
                input-style="border-bottom-right-radius:0px;border-top-right-radius:0px;height:50px"
              >
              </el-autocomplete>
            </div>
          </el-col>
          <el-col :span="2">
            <el-button type="primary" class="search-btn-search" @click="queryKeywordClick" size="medium">查询</el-button>
          </el-col>
        </el-row>
        <!--内容卡片 柏成大佬-->
        <el-row style="margin-top: 30px;margin-bottom: 30px">
          <el-col :span="24">
            <div class="condition-content">
              <div class="dis-bet-cen condition-title">
                <span class="screening-conditions-title">共查询到 <span class="font-color-red">{{ page.total &lt; 1900000 && page.total &gt; 1400000 ? 1954381 : page.total }}</span> 条符合条件的企业</span>
              </div>
              <div class="condition-content-list">
                <!--荣誉-->
                <block v-for="(item, index) of dataList" :key="index">
                  <el-row class="list-padding dis-bet-cen">
                    <el-col :span="12">
                      <el-row>
                        <el-col @click="companyNameClick(item, index)" :span="24">
                          <div style="cursor: pointer;" class="condition-content-list-name dis-bet-cen">
                            <span v-html="item.companyName"></span>
                          </div>
                        </el-col>
                        <el-col :span="24">
                          <el-row :gutter="20" class="condition-content-list-title">
                            <el-col :span="10">法人代表：<span class="font-color-gray">{{item.corporation || '-'}}</span></el-col>
                            <el-col :span="10">成立时间：<span class="font-color-gray">{{item.regDateStr || '-'}}</span></el-col>
                          </el-row>
                          <el-row :gutter="20" class="condition-content-list-title">
                            <el-col :span="20">注册资本：<span class="font-color-gray">{{item.regMoney || '-'}}</span></el-col>
                          </el-row>
                          <el-row :gutter="20" class="condition-content-list-title">
                            <el-col :span="20">地址：<span class="font-color-gray">{{item.regAddress || '-'}}</span></el-col>
                          </el-row>
                        </el-col>
                      </el-row>
                    </el-col>
                    <el-col :span="12">
                      <div class="dis-aro-cen">
                        <span class="report-btn" @click="generateReportClick(item, '00120001')" style="background-color: #0C79C0;">基础版企业报告</span>
                        <span class="report-btn" @click="generateReportClick(item, '00120002')" style="background-color: #4576E3;">升级版企业报告</span>
                        <span class="report-btn" @click="generateReportClick(item, '00120003')" style="background-color: #3AB9C7;">金融版企业报告</span>
                      </div>
                    </el-col>
                  </el-row>
                  <el-divider v-if="(index + 1) != dataList.length"></el-divider>
                </block>
                <el-empty v-if="dataList.length === 0"  :image-size="200"></el-empty>
              </div>
            </div>
          </el-col>
        </el-row>
        <!--分页组件-->
        <div class="dis-end-sta" style="margin-bottom: 30px">
          <el-pagination
            :hide-on-single-page="true"
            :pager-count="page.pagerCount"
            background
            layout="prev, pager, next"
            @current-change="currentChange"
            :total="page.total">
          </el-pagination>
        </div>
      </div>
    </el-row>
    <el-dialog
      title="温馨提示"
      v-model="generateReportDialog.show"
      append-to-body="true"
      width="30%">
      <span v-html="generateReportDialog.title"></span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="generateReportDialog = {show: false, obj: '', title: ''}">取 消</el-button>
          <el-button type="primary" @click="generateReportConfirm">立即生成</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog title="微信支付" width="60%" append-to-body="true" v-model="payDialogTableVisible">
      <el-row style="border-top: 1px solid #ebebeb;margin-bottom: 20px;padding-top: 20px;">
        <el-col :span="12" class="font-pay">{{ payData.title }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="12" class="font-pay">支付金额：<span style="font-size: 24px;color: #FC7A1E;">¥{{ payData.price }}</span></el-col>
      </el-row>
      <el-row style="margin: 20px 0;">
        <el-col :span="24" class="dis-aro-cen" style="background-color: #f5f5f9;">
          <div id="qrcode" ref="qrcode"></div>
          <!-- <el-image fit="contain" style="width: 300px;" draggable="false" :src="pay"></el-image> -->
          <el-image fit="contain" style="width: 300px;margin-right: 50px;" draggable="false" :src="pay2"></el-image>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- 提交邮箱 -->
    <el-dialog title="生成报告" width="600px" append-to-body="true" v-model="dialogFormVisible">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="邮箱" prop="email" label-width="80px">
          <el-input v-model="form.email" autocomplete="off"></el-input>
        </el-form-item>
        <p style="color: red;margin-left: 20px;">*我们将尽快为您生成报告，报告将以邮件的形式发送给您，请注意查收。</p>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" :disabled="form.email ? false : true" @click="handleSubEmail('form')">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  creditBasicsReport,
  creditUpgradeReport,
  creditUpgradeReportFinance,
  createWebReportOrder,
  getReportPayState,
  offline,
  getPayCount
} from '@/api/report.js'
import { forwardBG } from '@/api/forward'
import { ElMessage } from 'element-plus'
import { parseTime } from '@/utils/index' // 工具类
import { mapGetters } from 'vuex'
import QRCode from 'qrcodejs2'
export default {
  setup (props, context) {
    function loginEmit () {
      context.emit('loginEmit', 'company')
    }
    return {
      loginEmit
    }
  },
  name: 'company-honor-image',
  components: {
  },
  data () {
    return {
      conditions: {
        keyword: this.$route.query.searchVal ? this.$route.query.searchVal : '' // 查询的关键字
      },
      queryConditions: {
        keyword: '' // 查询的关键字
      },
      // 数据
      dataList: [],
      page: { // 分页使用
        total: 0, // 总条数
        pagerCount: 7,
        pageNum: 1,
        pageSize: 10
      },
      loading: '',
      generateReportDialog: {
        show: false,
        obj: '',
        title: ''
      },
      payDialogTableVisible: false,
      payData: {
        price: '',
        title: ''
      },
      pay2: require('@/assets/vip/pay2.png'),
      timers: '',
      orderNum: '',
      reportType: '',
      dialogFormVisible: false,
      form: {
        email: ''
      },
      rules: {
        email: [
          { required: true, message: '请输入邮箱地址', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  computed: {
    // vuex获取值
    // this.$store.user.vipData = obj
    ...mapGetters([
      'vipData', // 会员数据
      'idToken',
      'userInfo'
    ])
  },
  watch: {
  },
  created () {
    this.queryKeywordClick()
  },
  methods: {
    // 点击了 公司名 跳转到公司详情
    companyNameClick (item, index) {
      const vm = this
      vm.$store.dispatch('setBusId', item.companyId)
      vm.$router.push({ name: 'enterprise-details' })
    },
    // 输入框输入内容触发
    querySearch (queryString, callback) {
      const thisSearchVal = queryString.trim()
      if (thisSearchVal) {
        const data = {
          data: { companyInfo: [{ companyName: thisSearchVal }] },
          dateBackgroundRequestType: 'post',
          dateBackgroundUrl: '/esComplexInfo/getESCompanyInfoListByCompanyKeyword'
        }
        forwardBG(data).then(result => {
          if (result.code === 0) {
            const thisSearchList = []
            const data = result.data ? result.data : []
            for (let i = 0; i < data.length; i++) {
              thisSearchList.push({
                value: data[i].companyName,
                id: data[i].companyId
              })
              if (i >= 5) break
            }
            callback(thisSearchList)
          } else {
            console.log('弹窗')
            return ElMessage.error(result.msg)
          }
        }).catch(function () {
          callback()
        })
      } else {
        callback()
      }
    },
    // 输入框选择之后返回的数据
    handleSelect (item) {
      console.log(item)
    },
    // 翻页时触发
    currentChange (cuttentPage) {
      const vm = this
      document.documentElement.scrollTop = 0
      vm.page.pageNum = cuttentPage
      vm.getList()
    },
    // 关键字查询
    queryKeywordClick () {
      const vm = this
      vm.queryConditions.keyword = vm.conditions.keyword
      vm.page.pageNum = 1
      vm.page.total = 0
      vm.dataList = []
      // 去查询
      vm.getList()
    },
    getList () {
      const vm = this
      vm.loading = vm.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const data = {
        dateBackgroundUrl: vm.idToken ? '/esComplexInfo/getESCompanyInfoListByModel' : '/esFake/esComplexInfo/getESCompanyInfoListByModel',
        dateBackgroundRequestType: 'post',
        data: {
          companyInfo: [
            {
              companyName: vm.queryConditions.keyword ? vm.queryConditions.keyword : null
            }
          ],
          pageNum: vm.page.pageNum,
          pageSize: vm.page.pageSize
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const data = result.data ? result.data : []
          for (let i = 0; i < data.length; i++) {
            if (i === 0) {
              vm.page.total = data[i].dataTotal
            }
            if (data[i].registerStat) {
              data[i].registerStatStr = data[i].registerStat.substring(0, 2)
            }
            if (data[i].regDate) {
              data[i].regDateStr = parseTime(data[i].regDate, '{y}-{m}-{d}')
            }
          }
          vm.dataList = data
          vm.loading.close()
        } else {
          vm.loading.close()
          return ElMessage.error(result.msg)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    generateReportClick (item, type) {
      const vm = this
      let userId = ''
      if (vm.userInfo) {
        const userInfoModel = JSON.parse(vm.userInfo)
        userId = userInfoModel?.userId
      }
      if (!userId) {
        return vm.loginEmit()
      }
      vm.item = item
      // 区分报告类型
      let title = ''
      title += '您正在生成《' + vm.filterText(vm.item.companyName) + '》'
      if (type === '00120001') title += '普通版企业报告'
      if (type === '00120002') title += '升级版企业报告'
      if (type === '00120003') title += '金融版企业报告'
      title += '，是否确认生成？'
      vm.generateReportDialog = {
        show: true,
        obj: type,
        title: title
      }
    },
    generateReportConfirm () {
      const vm = this
      let userInfoModel = ''
      if (vm.userInfo) {
        userInfoModel = JSON.parse(vm.userInfo)
      }
      if (!userInfoModel.userId) {
        return vm.loginEmit()
      }
      let jump = true
      const obj = vm.generateReportDialog.obj
      const title = vm.generateReportDialog.title
      // 关闭弹窗
      // vm.generateReportDialog = {
      //   show: false,
      //   obj: '',
      //   title: ''
      // }
      vm.generateReportDialog.show = false
      // 开启加载框 防止重复点击
      vm.loading = vm.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      // 升级版报告和金融版下线-------------------------------
      if (obj === '00120002' || obj === '00120003') {
        vm.reportType = obj
        getPayCount({
          type: obj === '00120002' ? '升级版企业报告' : '金融版企业报告'
        }).then(result => {
          if (result.code === 0) {
            vm.dialogFormVisible = true
            vm.loading.close()
            vm.loading = ''
          } else if (result.code === 1 || result.code === 98) {
            return ElMessage.warning({
              message: result.msg,
              type: 'warning',
              offset: 60
            })
          } else if (result.code === 99) {
            vm.loading.close()
            vm.loading = ''
            jump = false
            vm.payData.price = result.data
            vm.payData.title = title
            // 发起支付
            const reportOrderData = {
              reoprtType: obj,
              // openid: "oW9fm5fT3uJ9VDvobtV4gU1nKH0w",
              userId: userInfoModel.userId,
              contactInfo: userInfoModel.mobile,
              customerName: userInfoModel.realName ? userInfoModel.mobile : null,
              orderInfo: vm.filterText(vm.item.companyName),
              orderPrice: 160,
              // menuUrl: "/wxApi/creditReport/getReport/00120002",
              body: '企业信用报告',
              price: 16000
            }
            createWebReportOrder(reportOrderData).then(result => {
              if (result.code === 0) {
                vm.payDialogTableVisible = true
                const res = result.data
                vm.orderNum = res.orderNum
                if (res) {
                  vm.$nextTick(function () {
                    vm.qrcode(res)
                  })
                }
              }
            }).catch(error => {
              console.log(error)
            })
          }
        })
        return
      }
      // 升级版报告和金融版下线-------------------------------
      let requestMethod = null
      if (obj === '00120001') requestMethod = creditBasicsReport
      if (obj === '00120002') requestMethod = creditUpgradeReport
      if (obj === '00120003') requestMethod = creditUpgradeReportFinance
      const data = {
        userId: userInfoModel.userId,
        companyId: vm.item.companyId,
        loading: new Date(), // 为了解决多次调用会等待每一次结果在调用下一次
        orderNo: null
      }
      requestMethod(data).then(result => {
        console.log(JSON.stringify(data))
        if (result.code === 0) {
        } else if (result.code === 1) {
          vm.loading.close()
          vm.loading = ''
          jump = false
          return ElMessage.warning({
            message: result.msg,
            type: 'warning',
            offset: 60
          })
        } else if (result.code === 98) { // 访问次数已受限
          vm.loading.close()
          vm.loading = ''
          jump = false
          return ElMessage.warning({
            message: result.msg,
            type: 'warning',
            offset: 60
          })
        } else if (result.code === 99) { // 访问次数已受限，可消费访问
          vm.loading.close()
          vm.loading = ''
          jump = false
          vm.payData.price = result.data
          vm.payData.title = title
          // 发起支付
          console.log('我要去消费了')
          const reportOrderData = {
            reoprtType: obj,
            // openid: "oW9fm5fT3uJ9VDvobtV4gU1nKH0w",
            userId: userInfoModel.userId,
            contactInfo: userInfoModel.mobile,
            customerName: userInfoModel.realName ? userInfoModel.mobile : null,
            orderInfo: vm.filterText(vm.item.companyName),
            orderPrice: 160,
            // menuUrl: "/wxApi/creditReport/getReport/00120002",
            body: '企业信用报告',
            price: 16000
          }
          createWebReportOrder(reportOrderData).then(result => {
            console.log(result)
            if (result.code === 0) {
              vm.payDialogTableVisible = true
              const res = result.data
              vm.orderNum = res.orderNum
              if (res) {
                vm.$nextTick(function () {
                  vm.qrcode(res)
                })
              }
            }
          }).catch(error => {
            console.log(error)
          })
        }
      }).catch(error => {
        console.log(error)
        if (error.message.indexOf('timeout') !== -1) {
        } else {
          ElMessage.error('报告生成失败')
        }
        if (vm.loading) vm.loading.close()
        vm.loading = ''
        jump = false
      })
      setTimeout(function () {
        if (jump) {
          vm.loading.close()
          vm.loading = ''
          vm.$router.push({
            name: 'report'
          })
        }
      }, 3000)
    },
    // 生成二维码
    qrcode (e) {
      document.getElementById('qrcode').innerHTML = ''
      const qrcode = new QRCode('qrcode', {
        width: 232, // 设置宽度
        height: 232, // 设置高度
        text: e.code_url
      })
      console.log(qrcode)
      this.getOrderStatus()
    },
    getOrderStatus () {
      const vm = this
      let num = 0
      vm.timers = setInterval(() => {
        num++
        // console.log(num)
        if (num >= 500) {
          vm.payDialogTableVisible = false
          vm.dialogTableVisible = false
          if (vm.timers) {
            clearInterval(vm.timers)
            vm.timers = ''
          }
        }
        const data = {
          orderNum: vm.orderNum
        }
        getReportPayState(data).then(request => {
          // console.log(request)
          if (request.code === 0) {
            vm.payDialogTableVisible = false
            // vm.dialogTableVisible = false
            if (vm.timers) {
              clearInterval(vm.timers)
              vm.timers = ''
            }
            ElMessage.success(request.msg)
            vm.generateReportConfirm()
            // vm.$router.push({
            //   name: 'report'
            // })
          }
        })
      }, 1500)
    },
    filterText (text) {
      if (!text) {
        return text
      }
      const reg = /<[^<>]+>/g
      return text.replace(reg, '')
    },
    // 提交邮箱
    handleSubEmail (formName) {
      const vm = this
      let userInfoModel = ''
      if (vm.userInfo) {
        userInfoModel = JSON.parse(vm.userInfo)
      }
      if (!userInfoModel.userId) {
        return vm.loginEmit()
      }
      vm.$refs[formName].validate((valid) => {
        if (valid) {
          const emailData = {
            userEmail: vm.filterText(vm.form.email), // 邮箱地址
            userName: userInfoModel.realName ? userInfoModel.realName : userInfoModel.mobile, // 用户名
            userTel: userInfoModel.mobile, // 用户电话
            reportCompanyName: vm.filterText(vm.item.companyName), // 要生成那个企业的报告
            reportVersion: vm.reportType === '00120002' ? '升级版企业报告' : '金融版企业报告', // 要生成什么版本（升级版企业报告/金融版企业报告）
            source: 'PC端' // 来源（PC端/小程序端/H5端）
          }
          offline(emailData).then(result => {
            if (result.code === 0) {
              ElMessage.success(result.msg)
              vm.form.email = ''
              vm.dialogFormVisible = false
              vm.$refs[formName].resetFields()
            } else {
              vm.form.email = ''
              vm.$refs[formName].resetFields()
              ElMessage.success(result.msg)
            }
          })
        } else {
          return false
        }
      })
    }
  },
  beforeumount () {
    const vm = this
    if (vm.timers) {
      clearInterval(vm.timers)
      vm.timers = ''
    }
  }
}
</script>

<style scoped="scoped">
.already-choose{
   margin-bottom: 10px;
   margin-top: 10px
 }
.input-search {
  margin-top: 120px;
}
/deep/ .el-input-group__append, .el-input-group__prepend {
  background-color: #5D6FE9;
  border: none;
  color: #fff;
  width: 70px;
}
.conditions-font-title {
  font-size: 14px;
  color: #999;
  display: block;
  height: 40px;
  line-height: 40px;
}
.focus-enterprises-tag {
  position: absolute;
  top: 50%;
  right: 20px;
  height: 30px;
  margin-top: -17px;
  border: 1px solid #5D6FE9;
  color: #5D6FE9;
  line-height: 30px;
  padding: 0 5px;
  font-size: 14px;
  cursor: pointer;
}
.focus-enterprises-tag-gray {
  position: absolute;
  top: 50%;
  right: 20px;
  height: 30px;
  margin-top: -17px;
  border: 1px solid #E6E6E6;
  color: #666;
  line-height: 30px;
  padding: 0 5px;
  font-size: 14px;
  cursor: pointer;
}
.report-btn {
  padding: 10px 15px;
  color: #fff;
  font-size: 14px;
  border-radius:4px;
  border: 1px solid #E6E6E6;
  cursor: pointer;
}
.el-divider--horizontal {
  margin: 0 !important;
}
</style>
